type HeroQuestion = {
  categoryCode: string
  categoryName: string
  question: string
}

const heroQuestions: HeroQuestion[] = [
  {
    categoryCode: 'first-date',
    categoryName: 'Вопросы на первом свидании',
    question: 'Какие сериалы тебе нравятся?',
  },
  {
    categoryCode: 'dud',
    categoryName: 'Вопросы в стиле Дудя',
    question: 'Три твоих лучших музыкальных открытия за последнее время?',
  },
  {
    categoryCode: 'pozner',
    categoryName: 'Вопросы в стиле Познера',
    question: 'По двадцатибальной шкале какую оценку Вы бы себе дали?',
  },
  {
    categoryCode: 'about-partner',
    categoryName: 'Вопросы о Вашем партнере',
    question: 'Кто умнее - Вы или Ваш партнер?',
  },
  {
    categoryCode: 'about-love',
    categoryName: 'Вопросы о любви',
    question: 'Вас не смущают поцелуи на людях?',
  },
]

export { heroQuestions }
